$padding-value: 4px;
$border-radius-value: 6px;

.mat-select-value {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.add-edit-product {
  width: calc(100% - 20px);

  form {
    .rtlError {
      margin-bottom: 0.5em;
      position: absolute;
      left: 3em;

      mat-icon {
        color: red;
      }
    }

    .ltrError {
      margin-bottom: 0.5em;
      position: absolute;
      right: 3em;

      mat-icon {
        color: red;
      }
    }

    mat-label {
      align-self: flex-start;
      font-size: 13px;
      font-weight: 600;
    }

    .mat-select-trigger {
      width: unset !important;
      margin: unset !important;
    }

    .field-content {
      box-shadow: 0.5px 0.5px 5px 0.5px #aaaaaa;
      border-radius: $border-radius-value;
      width: 100%;
    }

    mat-form-field {
      width: calc(100% - (#{$padding-value} * 5));
      align-self: flex-start;

      input {
        height: 40px;
        font-size: 13px;
        font-weight: 600;
        box-shadow: 0.5px 0.5px 5px 0.5px #aaaaaa;
        border-radius: $border-radius-value;
        width: 100%;
        padding: 0 $padding-value;
      }

      mat-select {
        font-size: 13px;
        font-weight: 600;
        box-shadow: 0.5px 0.5px 5px 0.5px #aaaaaa;
        // height: 40px;
        width: 100%;
        border-radius: $border-radius-value;
        padding: 12px $padding-value;
      }

      textarea {
        margin-top: 4px;
        width: 96%;
        height: 80px;
        font-size: 13px;
        font-weight: 600;
        box-shadow: 0.5px 0.5px 5px 0.5px #aaaaaa;
        border-radius: $border-radius-value;
        padding: 10px;
      }

      mat-checkbox {
        font-size: 13px;
        font-weight: 600;
      }
    }

    .attach-file-text {
      margin-bottom: 1.2em;
    }

    .image-button-content {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 80px;
      height: 80px;
      margin: .5em;
      border-radius: $border-radius-value;

      .image-button {
        width: 100%;
        height: 100%;
        border: dashed grey;

        mat-icon {
          color: red;
        }
      }
    }

    .video-button-content {
      width: 80px;
      height: 80px;
      margin: .5em;

      .video-button {
        width: 100%;
        height: 100%;
        border: dashed grey;

        mat-icon {
          color: red;
        }
      }
    }

    .add-button {
      width: calc(100% - (#{$padding-value} * 2));
      margin-top: 1em;
      margin-bottom: 16px;
    }

    .cancel-button {
      width: calc(100% - (#{$padding-value} * 2));
      margin-bottom: 2em;
    }
  }
}

.add-edit-product-desktop {
  display: none;
}


.video-button-video {
  width: calc(80px - .5em);
  height: calc(80px - .5em);
  margin: .5em;
  border: dashed grey;
  border-radius: $border-radius-value;
}

///for MD/ and LG
@media screen and (min-width: 960px) and (max-width: 1279px) {
  .header {
    display: none;
  }

  .add-edit-product {
    display: none;
  }

  .add-edit-product-desktop {
    display: flex;
    padding-top: 80px;

    .header-title h2 {
      line-height: 18px;
      font-weight: 700;
      font-size: 2em;
      margin: unset;
    }

    mat-form-field {
      input {
        font-size: 18px;
        font-weight: 500;
        color: black;
        padding: 18px 20px;
        width: 95.7%;
        border: solid 1px #dfdfdf;
        border-radius: 5px;
      }
    }

    mat-form-field {
      mat-select {
        font-size: 18px;
        font-weight: 500;
        color: black;
        height: 3em;
        border: solid 1px #dfdfdf;
        border-radius: 5px;
        .mat-select-trigger {
          padding: 15px;
          width: 100%;
        }
      }
    }

    mat-form-field {
      textarea {
        font-size: 18px;
        font-weight: 500;
        color: black;
        height: 10em;

        border: solid 1px #dfdfdf;
        border-radius: 5px;
        padding: 5px;
      }
      .mat-form-field-wrapper {
        padding: 0;
      }
    }

    mat-icon {
      color: red;
    }

    .add-button {
      width: 250px;
      bottom: 5%;
      height: 59px;
      font-weight: bold;
      font-size: 19px;
    }

    .attach-file-text {
      margin-top: 0.8em;
      margin-bottom: 1.2em;
    }

    .image-button-content {
      width: 80px;
      height: 80px;
      background: url("/assets/images/cow-placeholder.jpg") no-repeat center;
      background-size: cover;
      margin: .5em;
      border-radius: $border-radius-value;

      .image-button {
        width: 100%;
        height: 100%;
        border: dashed grey;

        mat-icon {
          color: red;
        }
      }
    }

    .video-button-content {
      width: 80px;
      height: 80px;
      margin: .5em;

      .video-button {
        width: 100%;
        height: 100%;
        border: dashed grey;

        mat-icon {
          color: red;
        }
      }
    }

    mat-checkbox ::ng-deep .mat-checkbox-inner-container {
      width: 37px;
      height: 37px;
      border-radius: 3px !important;
    }

    mat-checkbox ::ng-deep .mat-checkbox-label {
      line-height: 31px !important;
    }
  }
}

/// for XL
@media screen and (min-width: 1280px) and (max-width: 5000px) {
  .header {
    display: none;
  }

  .add-edit-product {
    display: none;
  }

  .add-edit-product-desktop {
    display: flex;
    padding-top: 80px;

    .header-title h2 {
      line-height: 18px;
      font-weight: 700;
      font-size: 2em;
      margin: unset;
    }

    mat-form-field {
      input {
        font-size: 18px;
        font-weight: 500;
        color: black;
        padding: 18px 20px;
        width: 96.5%;
        border: solid 1px #dfdfdf;
        border-radius: 5px;
      }
    }

    mat-form-field {
      mat-select {
        font-size: 18px;
        font-weight: 500;
        color: black;
        height: 3em;
        border: solid 1px #dfdfdf;
        border-radius: 5px;
        .mat-select-trigger {
          padding: 15px;
          width: 100%;
        }
      }
    }

    mat-form-field {
      textarea {
        font-size: 18px;
        font-weight: 500;
        color: black;
        height: 10em;

        border: solid 1px #dfdfdf;
        border-radius: 5px;
        padding: 5px;
      }
      .mat-form-field-wrapper {
        padding: 0;
      }
    }

    mat-icon {
      color: red;
    }

    .add-button {
      width: 250px;
      bottom: 5%;
      height: 59px;
      font-weight: bold;
      font-size: 19px;
    }

    .attach-file-text {
      margin-top: 0.8em;
      margin-bottom: 1.2em;
    }

    .image-button-content {
      width: 80px;
      height: 80px;
      background: url("/assets/images/cow-placeholder.jpg") no-repeat center;
      background-size: cover;
      margin: .5em;
      border-radius: $border-radius-value;

      .image-button {
        width: 100%;
        height: 100%;
        border: dashed grey;

        mat-icon {
          color: red;
        }
      }
    }

    .video-button-content {
      width: 80px;
      height: 80px;
      margin: .5em;

      .video-button {
        width: 100%;
        height: 100%;
        border: dashed grey;

        mat-icon {
          color: red;
        }
      }
    }

    mat-checkbox ::ng-deep .mat-checkbox-inner-container {
      width: 37px;
      height: 37px;
      border-radius: 3px !important;
    }

    mat-checkbox ::ng-deep .mat-checkbox-label {
      line-height: 31px !important;
    }
  }
}
