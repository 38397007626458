
#post-item-desktop:hover {
  cursor: pointer;
}

.filler-post-item {
  width: calc((1161px / 5) - 20px);
  min-width: calc((1161px / 5) - 20px);
}

.edit-btn, .fave-btn {
  z-index: 49!important;
}

#post-item-desktop {
  display: none;
  width: calc((1161px / 5) - 20px);
  border-radius: 8px;

  img, .product-img {
    width: 100%;
    height: 200px;
    border-radius: 8px;
  }

  .product-img {
    background: url("/assets/images/Avatar.png") no-repeat center;
    background-size: cover;
    display: flex;
      align-items: flex-end;
      justify-content: space-between;

    .fave-btn {
      top: 8px;
      right: 161px;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 25px;
  }

  .label-desktop {
    .label-icon {
      width: 15.8px;
      height: auto;
    }
    span {
      font-size: 15px;
      color: #ADADAD;
    }
  }
}


///for MD/
@media screen and (min-width: 960px) and (max-width: 1279px) {
  #post-item {
    display: none;
  }

  .filler-post-item {
    width: calc((935px / 5) - 20px) !important;
    min-width: calc((935px / 5) - 20px) !important;
  }

  #post-item-desktop {
    display: flex;
    width: calc((935px / 5) - 20px) !important;

    h2 {
      font-size: 18px;
      display: inline-block;
      width: 180px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }

    img {
      height: calc((935px / 5) - 20px) !important;
    }
  }
}

///for LG/
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  #post-item {
    display: none;
  }

  #post-item-desktop {
    display: flex;

    h2 {
      font-size: 18px;
      display: inline-block;
      width: 180px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  }
}

///for XL/
@media screen and (min-width: 1920px) and (max-width: 5000px) {
  #post-item {
    display: none;
  }

  #post-item-desktop {
    display: flex;

    h2 {
      font-size: 18px;
      display: inline-block;
      width: 180px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  }
}
