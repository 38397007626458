// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@font-face {
  font-family: 'Poppins-Medium';
  src: url('assets/fonts/poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Black';
  src: url('assets/fonts/poppins/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('assets/fonts/poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('assets/fonts/poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

// ------------------------------ ARABIC ------------------------------ //
@font-face {
  font-family: 'URW DIN Arabic';
  src: url('assets/fonts/arabe/URWDINArabic-Regular.woff2') format('woff2'),
  url('assets/fonts/arabe/URWDINArabic-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'URW DIN Arabic Black';
  src: url('assets/fonts/arabe/URWDINArabic-Black.woff2') format('woff2'),
  url('assets/fonts/arabe/URWDINArabic-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'URW DIN Arabic Bold';
  src: url('assets/fonts/arabe/URWDINArabic-Bold.woff2') format('woff2'),
  url('assets/fonts/arabe/URWDINArabic-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'URW DIN Arabic Demi';
  src: url('assets/fonts/arabe/URWDINArabic-Demi.woff2') format('woff2'),
  url('assets/fonts/arabe/URWDINArabic-Demi.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'URW DIN Arabic Medium';
  src: url('assets/fonts/arabe/URWDINArabic-Medium.woff2') format('woff2'),
  url('assets/fonts/arabe/URWDINArabic-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'URW DIN Arabic Light';
  src: url('assets/fonts/arabe/URWDINArabic-Light.woff2') format('woff2'),
  url('assets/fonts/arabe/URWDINArabic-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'URW DIN Arabic Thin';
  src: url('assets/fonts/arabe/URWDINArabic-Thin.woff2') format('woff2'),
  url('assets/fonts/arabe/URWDINArabic-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'URW DIN Arabic XLight';
  src: url('assets/fonts/arabe/URWDINArabic-XLight.woff2') format('woff2'),
  url('assets/fonts/arabe/URWDINArabic-XLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@import 'halal-theme';

$primary-color: #48b041;
$app-gray: #F6F6F6;

// font weight
$arabic: URW DIN Arabic;
$arabic-black: URW DIN Arabic Black;
$arabic-medium: URW DIN Arabic Medium;
$arabic-bold: URW DIN Arabic Bold;
$arabic-demi: URW DIN Arabic Demi;

// font weight poppins
$english: Poppins-Regular;
$english-black: Poppins-Black;
$english-medium: Poppins-Medium;
$english-bold: Poppins-Bold;
$english-demi: Poppins-SemiBold;

:root {
  --primary-color: #48b041;
  --active-blue: #6385E5;
  --app-gray: #F6F6F6;
  --arabic: URW DIN Arabic;
  --arabic-black: URW DIN Arabic Black;
  --arabic-medium: URW DIN Arabic Medium;
  --arabic-bold: URW DIN Arabic Bold;
  --arabic-demi: URW DIN Arabic Demi;
  --english: Poppins-Regular;
  --english-black: Poppins-Black;
  --english-medium: Poppins-Medium;
  --english-bold: Poppins-Bold;
  --english-demi: Poppins-SemiBold;
  --star-active: #FFCC00;
  --star-inactive: #EFEFF4;
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  position: relative;
}

html[lang='ar'] body {
  margin: 0;
  font-family: URW DIN Arabic, sans-serif !important;
}

html[lang='en'] body {
  margin: 0;
  font-family: Poppins-Regular, sans-serif !important;
}

.mat-form-field-infix {
  line-height: normal !important;
}

.mat-form-field-underline {
  display: none;
  background-color: #e8e8e8 !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: white !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0 !important;
}

.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  padding-top: 1.3em;
}

.toolbar-header {
  position: fixed;
  z-index: 50;
  background-color: #fff;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.flex-wrap {
  flex-wrap: wrap;
}

.spacer {
  flex: 1 1 auto;
}

.half-width-no-div {
  width: calc(50% - 2px);
}

.half-width {
  width: 50%;
}

.half-height {
  height: 50%;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-width-v {
  width: 100vw;
}

.auto-height {
  height: auto;
}

.full-height-v {
  height: 100vh;
}

.center-vertically {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between !important;
  align-items: center;
}

.space-evenly {
  justify-content: space-evenly !important;
  align-items: flex-start;
}

.center-horizontally {
  display: flex;
  flex-direction: row;
}

.justify-align-center {
  justify-content: center;
  align-items: center;
}

.justify-align-top-vertical {
  justify-content: flex-start;
  align-items: center;
}

.justify-align-bottom-vertical {
  justify-content: flex-end;
  align-items: center;
}

.justify-align-top-horizontal {
  justify-content: center;
  align-items: flex-start;
}

.justify-align-bottom-horizontal {
  justify-content: center;
  align-items: flex-end;
}

.justify-align-left-vertical {
  justify-content: center;
  align-items: flex-start;
}

.justify-align-left-horizontal {
  justify-content: flex-start;
  align-items: center;
}

.justify-align-right-vertical {
  justify-content: center;
  align-items: flex-end;
}

.justify-align-right-horizontal {
  justify-content: flex-end;
  align-items: center;
}

.header_btn {
  min-width: 80px;
  min-height: 40px;
}

mat-carousel > div > div > button.mat-focus-indicator.mat-mini-fab.mat-button-base.mat-accent.mat-button-disabled {
  background: var(--primary-color) !important;
}


.center-fab .mat-button-wrapper {
  padding: unset !important;
}

.mat-toolbar-single-row {
  padding: 0 !important;
  top : 0;
}

.main-div-full-width {
  padding: 0 10px;
  width: calc(100% - 20px);
}

.main-div-full-width-all-p {
  padding: 10px;
  width: calc(100% - 20px);
}

.main-component-styles {
  //width: 100%;
  min-height: 80vh;
  padding-top: 70px;
  //padding-bottom: 120px;
  background-color: #fff;
}

.mat-list-item-content {
  //min-height: 72px!important;
}

.hidden-cuz-of-route-changes {
  display: none !important;
}

button.mat-flat-button[color='primary'], button.mat-flat-button[color='red'], button.mat-flat-button[color='warn'] {
  height: 46px !important;
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.min-page-height {
  min-height: 60vh;
}

img {
  object-fit: cover;
  object-position: center;
  height: auto;
  max-width: 100%;
}

.sections-title {
  align-self: flex-start;
  font-size: 30px;
  font-weight: bold;
}

.avatar-in-bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.force-ltr {
  direction: ltr !important;
}

.text-rtl {
  text-align: right !important;
}

.text-ltr {
  text-align: left !important;
}

.carousel-slide {
  height: 35vh !important;
  padding-bottom: unset !important;
}

.rotate-icon {
  transform: rotate(180deg);
}

.absolute-position {
  position: absolute;
}

.text-center {
  text-align: center !important;
}

.z-index-9999 {
  z-index: 9999 !important;
}

.z-index-999 {
  z-index: 999;
}

.z-index-99 {
  z-index: 99;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-13 {
  margin-top: 13px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-b-70 {
  margin-bottom: 70px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.p-b-100 {
  padding-bottom: 100px;
}

.p-b-60 {
  padding-bottom: 60px;
}

.p-b-50 {
  padding-bottom: 50px;
}

.p-b-80 {
  padding-bottom: 80px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-t-60 {
  padding-top: 60px;
}

.p-t-80 {
  padding-top: 80px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-r-4 {
  margin-right: 4px;
}

.m-l-4 {
  margin-left: 4px;
}

.m-v-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.m-h-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.p-v-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.p-v-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.p-v-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.p-h-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.p-h-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.p-5 {
  padding: 5px
}

.p-8 {
  padding: 8px
}

.p-10 {
  padding: 10px
}

.p-v-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.p-15 {
  padding: 15px
}

.p-20 {
  padding: 20px;
}

.p-h-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.p-h-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.p-h-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-r-30 {
  padding-right: 30px;
}

.overflow-y-scroll {
  overflow-y: scroll;
  //max-height: 100%;
}

.mat-button-base:not(.mat-icon-button):not(.mat-fab):not(.left-radius):not(.right-radius) {
  border-radius: 9px !important;
}

.unset-align-items {
  align-items: unset !important;
}

.unset-justify-content {
  justify-content: unset !important;
}

// declared for IDE indexing.
.main-views-container {
}

.no-content {
  height: 50%;
  width: 50%;
  margin-bottom: 10px;
}

.no-content-text {
  font-weight: normal;
  color: rgb(168, 168, 168);
  font-size: 14px;
}

.desktop-content {
  min-height: 60vh;
}

.halal-square-element {
  $report-item-small-screen: calc((935px / 5) - 20px);
  $report-item-big-screen: calc((1161px / 5) - 20px);

  border-radius: 8px;

  @media screen and (min-width: 960px) and (max-width: 1279px) {
    width: $report-item-small-screen;
    height: $report-item-small-screen;
  }

  @media screen and (min-width: 1280px) and (max-width: 5000px) {
    width: $report-item-big-screen;
    height: $report-item-big-screen;
  }
}


///for MD/
@media screen and (min-width: 960px) and (max-width: 1279px) {
  .main-views-container {
    width: 935px !important;
  }

  .carousel-slide {
    height: 544px !important;
    width: 556px !important;
    border-radius: 6px;
  }
  .no-content-text {
    font-size: 20px;
  }

  .no-content {
    height: auto;
    width: 200px;
  }
}

///for LG/
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .main-views-container {
    width: 1161px !important;
  }

  .no-content {
    height: auto;
    width: 200px;
  }
  .no-content-text {
    font-size: 20px;
  }

  .carousel-slide {
    height: 544px !important;
    width: 556px !important;
    border-radius: 6px;
  }
}


///for XL/
@media screen and (min-width: 1920px) and (max-width: 5000px) {
  .main-views-container {
    width: 1161px !important;
  }

  .no-content {
    height: auto;
    width: 200px;
  }
  .no-content-text {
    font-size: 20px;
  }

  .carousel-slide {
    height: 544px !important;
    width: 556px !important;
    border-radius: 6px;

  }
}



//importing pages specific styles
@import 'add-edit-product';
@import 'post-item-desktop';
@import 'add-report-send-msg-dialog-styles';
