.add-report-dialog {
  .close_button {
    color: grey;
  }

  .content {
    width: 100%;

    .title {
      font-size: 18px;
      font-weight: 600;
    }

    .add-report-form {
      mat-form-field {
        textarea {
          border-radius: 5px;
          height: 113px;
          width: calc(100% - 20px);
          padding: 10px;
          background-color: #EFEFF4;
          font-size: 15px;
          font-weight: 300;
          color: black !important;
        }
      }
    }

    .image-button-content {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 65px;
      height: 65px;
      margin: .5em;
      border-radius: 5px;

      .image-button {
        height: 100%;
        width: 100%;
        border: dashed grey;

        mat-icon {
          color: red;
        }
      }
    }

    .send-button {
      margin-top: 1.5em;
      height: 46px;
      font-size: 13px;
      font-weight: bold;
    }
  }
}


@media screen and (min-width: 960px) and (max-width: 1279px) {
  .add-report-dialog {
    .close_button {
      color: grey;
    }

    .content {
      .title {
        font-size: 18px;
        font-weight: 600;
      }

      .add-report-form {
        mat-form-field {
          textarea {
            padding: 14px;
            border-radius: 5px;
            background-color: #EFEFF4;
            opacity: .5;
            height: 113px;
            height: 100px;
            width: 90%;
            font-size: 15px;
            font-weight: 300;
          }
        }
      }

      .image-button-content {
        background: url("assets/images/cow-placeholder.jpg") no-repeat center;
        background-size: cover;
        width: 65px;
        height: 65px;
        margin: .5em;
        border-radius: 5px;

        .image-button {
          height: 100%;
          width: 100%;
          border: dashed grey;

          mat-icon {
            color: red;
          }
        }
      }

      .send-button {
        margin-top: 1.5em;
        height: 46px;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}

///for LG/
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .add-report-dialog {
    .close_button {
      color: grey;
    }

    .content {
      .title {
        font-size: 18px;
        font-weight: 600;
      }

      .add-report-form {
        mat-form-field {
          textarea {
            padding: 14px;
            border-radius: 5px;
            background-color: #EFEFF4;
            opacity: .5;
            height: 113px;
            height: 100px;
            width: 90%;
            font-size: 15px;
            font-weight: 300;
          }
        }
      }

      .image-button-content {
        background: url("assets/images/cow-placeholder.jpg") no-repeat center;
        background-size: cover;
        width: 65px;
        height: 65px;
        margin: .5em;
        border-radius: 5px;

        .image-button {
          height: 100%;
          width: 100%;
          border: dashed grey;

          mat-icon {
            color: red;
          }
        }
      }

      .send-button {
        margin-top: 1.5em;
        height: 46px;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}

///for XL/
@media screen and (min-width: 1920px) and (max-width: 5000px) {
  .add-report-dialog {
    .close_button {
      color: grey;
    }

    .content {
      .title {
        font-size: 18px;
        font-weight: 600;
      }

      .add-report-form {
        mat-form-field {
          textarea {
            padding: 14px;
            border-radius: 5px;
            background-color: #EFEFF4;
            opacity: .5;
            height: 100px;
            width: 90%;
            font-size: 15px;
            font-weight: 300;
          }
        }
      }

      .image-button-content {
        background: url("assets/images/cow-placeholder.jpg") no-repeat center;
        background-size: cover;
        width: 65px;
        height: 65px;
        margin: .5em;
        border-radius: 5px;

        .image-button {
          height: 100%;
          width: 100%;
          border: dashed grey;

          mat-icon {
            color: red;
          }
        }
      }

      .send-button {
        margin-top: 1.5em;
        height: 46px;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
